<template>
  <div>
    <v-card class="rounded-lg" elevation="0">
      <section class="header-table">
        <v-text-field
          v-model="search"
          class="rounded-lg"
          append-icon="mdi-magnify"
          label="Pesquise pelo nome do usuário..."
          @keyup.enter="searchRegister"
          single-line
          hide-details
          outlined
          dense
          clearable
        ></v-text-field>
        <div class="space"></div>
        <v-select
          dense
          outlined
          :items="profile_list"
          v-model="profile_search"
          @keyup.enter="searchRegister"
          item-text="name"
          item-value="id"
          label="Perfil"
          clearable
        ></v-select>
        <div class="space"></div>
        <v-select
          dense
          outlined
          :items="select_status_user"
          v-model="status_user"
          @keyup.enter="searchRegister"
          item-text="name"
          item-value="value"
          label="Status"
          clearable
        ></v-select>
        <div class="space"></div>

        <v-btn
          class="rounded-lg"
          elevation="0"
          color="success"
          @click="searchRegister"
        >
          <v-icon small class="mr-2" color="#FFFFFF"> mdi-magnify </v-icon>
          <span class="textBtn"> Pesquisar </span>
        </v-btn>
        <div class="space"></div>
        <v-btn
          class="rounded-lg"
          elevation="0"
          color="#C62828"
          @click="clearSearch"
        >
          <span class="textBtn"> LIMPAR</span>
        </v-btn>
        <div class="space"></div>
        <v-btn
          class="rounded-lg"
          elevation="0"
          color="primary"
          @click="dialog = true"
        >
          <v-icon small class="mr-2" color="#FFFFFF"> mdi-plus </v-icon>
          <span class="textBtn"> Criar Usuário </span>
        </v-btn>
      </section>

      <v-data-table
        :headers="headers"
        :items="usuarios"
        :footer-props="{
          'items-per-page-text': 'Usuários por Página',
        }"
        :page="page"
        :pageCount="numberOfPages"
        :options.sync="options"
        :sort-by.sync="sortBy"
        :server-items-length="totalUsers"
        :loading="loading"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <Crud :resource="item" v-on:eventname="crudUser" />
        </template>
        <template v-slot:[`item.cpf`]="{ item }">
          {{ item.cpf | VMask("###.###.###-##") }}
        </template>
      </v-data-table>

      <v-dialog v-model="dialog" width="600px" persistent>
        <v-card class="create-user-dialog rounded-lg">
          <div class="banner">
            <h4>Criar Usuário</h4>
          </div>

          <AlertError
            class="mb-5"
            v-model="displayError"
            :resource="displayError"
          />

          <v-form @submit.prevent="create" ref="form">
            <v-text-field
              clearable
              class="rounded-lg"
              label="Nome"
              placeholder="Nome completo"
              outlined
              v-model="name"
              :rules="nameRules"
              dense
            ></v-text-field>
            <v-text-field
              clearable
              class="rounded-lg"
              label="E-mail"
              placeholder="E-mail"
              outlined
              v-model="email"
              :rules="emailRules"
              dense
            ></v-text-field>
            <v-text-field
              clearable
              label="CPF"
              placeholder="CPF"
              outlined
              v-mask="'###.###.###-##'"
              v-model="cpf"
              dense
            ></v-text-field>

            <v-select
              clearable
              dense
              outlined
              :items="profile_list"
              v-model="profile"
              :rules="nameRules"
              item-text="name"
              item-value="id"
              label="Perfis"
            ></v-select>

            <v-select
              style="max-width: 200px"
              clearable
              v-if="showAgency"
              dense
              outlined
              :items="agency_list"
              v-model="agency_id"
              item-text="name"
              item-value="id"
              label="Orgão Entidade"
            ></v-select>

            <v-select
              clearable
              v-if="showCounty"
              dense
              outlined
              :items="county_list"
              v-model="county"
              :rules="nameRules"
              item-text="name"
              item-value="id"
              label="Municipio"
            ></v-select>

            <v-select
              clearable
              v-if="showInstitute"
              dense
              outlined
              :items="institute_list"
              v-model="institute_id"
              item-text="name"
              item-value="id"
              label="Instituição"
            ></v-select>

            <v-select
              clearable
              v-if="showRegionalHealthNucleus"
              dense
              outlined
              :items="regional_nucleus_health_list"
              v-model="regional_nucleus_health_id"
              item-text="name"
              item-value="id"
              label="Macrorregião"
            ></v-select>

            <v-select
              clearable
              v-if="showState"
              dense
              outlined
              :items="state_list"
              v-model="state_id"
              item-text="name"
              item-value="id"
              label="Estado"
            ></v-select>

            <v-card-actions class="cardFooter">
              <v-spacer></v-spacer>

              <v-btn
                class="rounded-lg"
                color="#e74c3c"
                text
                elevation="0"
                @click="dialog = false"
              >
                <span>Cancelar</span>
              </v-btn>

              <v-btn
                class="rounded-lg"
                elevation="0"
                color="#27ae60"
                type="submit"
              >
                <span class="textBtn">Enviar dados</span>
                <v-icon color="#FFFFFF" small class="ml-2"
                  >mdi-arrow-right</v-icon
                >
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-dialog>
    </v-card>

    <v-snackbar v-model="snackbar">
      {{ message }}

      <template v-slot:action="{ attrs }">
        <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">
          Fechar
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import axios from "@/axios";
import Crud from "./CrudUsers-comp.vue";
import AlertError from "../alerts/Error-component.vue";

export default {
  data() {
    return {
      select_status_user: [
        { name: "ATIVO", value: "ATIVO" },
        { name: "INATIVO", value: "INATIVO" },
      ],
      dialog: false,
      search: "",
      headers: [
        {
          text: "Nome",
          align: "start",
          value: "name",
          sortable: false,
        },
        { text: "CPF", value: "cpf", sortable: false },
        { text: "E-mail", value: "email", sortable: false },
        { text: "Perfil", value: "profile.name", sortable: false },
        { text: "Múnicipio", value: "county.name", sortable: false },
        { text: "Instituição", value: "institute.name", sortable: false },
        {
          text: "Macrorregião",
          value: "regional_nucleus_health.name",
          sortable: false,
        },
        { text: "Estado", value: "state.name", sortable: false },

        // { text: "Município", value: "county", sortable: false },
        { text: "Ações", value: "actions", sortable: false },
      ],

      nameRules: [(v) => !!v || "O campo é obrigatório"],
      emailRules: [
        (v) => !!v || "O campo é obrigatório",
        (v) => /.+@.+/.test(v) || "O e-mail não é válido.",
      ],
      cpfRules: [(v) => !!v || "O campo é obrigatório"],

      displayError: {
        display: false,
        message: [],
      },

      status_user: "",
      page: 1,
      totalUsers: 0,
      numberOfPages: 0,
      passengers: [],
      loading: true,
      options: {},
      sortBy: "name",
      profile_search: "",

      usuarios: [],
      message: "",
      snackbar: false,
      loadingBTN: false,
      exibirMensagem: false,
      messageError: "",

      name: "",
      cpf: "",
      email: "",
      ibge: "",
      profile_list: [],
      profile: "",
      county_list: [],
      county: "",
      agency_list: [],
      agency_id: "",
      institute_list: [],
      institute_id: "",
      regional_nucleus_health_list: [],
      regional_nucleus_health_id: "",
      state_list: [],
      state_id: "",
      showInstitute: false,
      showState: false,
      showCounty: false,
      showRegionalHealthNucleus: false,
      showAgency: false,
    };
  },

  components: {
    Crud,
    AlertError,
  },

  watch: {
    sortBy: {
      handler() {
        console.log(this.sortBy);
      },
    },

    profile(val) {
      switch (val) {
        case 3:
          this.showCounty = true;
          this.showAgency = true;
          this.showInstitute = false;
          this.showRegionalHealthNucleus = false;
          this.showState = false;
          break;

        case 4:
          this.showInstitute = true;
          this.showAgency = false;
          this.showCounty = false;
          this.showRegionalHealthNucleus = false;
          this.showState = false;
          break;
        case 6:
          this.showRegionalHealthNucleus = true;
          this.showAgency = true;
          this.showCounty = false;
          this.showInstitute = false;
          this.showState = false;
          break;

        case 8:
          this.showState = true;
          this.showAgency = true;
          this.showCounty = false;
          this.showInstitute = false;
          this.showRegionalHealthNucleus = false;
          break;

        default:
          this.showAgency = true;
          this.showCounty = false;
          this.showInstitute = false;
          this.showRegionalHealthNucleus = false;
          this.showState = false;
          break;
      }
    },

    options: {
      handler() {
        this.indoDataTable();
      },
    },
    deep: true,
  },

  methods: {
    searchRegister() {
      this.loading = true;
      const { page } = this.options;
      let pageNumber = page;
      let data = new Object();
      data.per_page = this.options.itemsPerPage;

      axios
        .get(`/users`, {
          params: {
            page: pageNumber,
            per_page: data.per_page,
            name: this.search,
            profile_id: this.profile_search,
            status_user: this.status_user,
          },
        })
        .then((response) => {
          this.loading = false;
          this.usuarios = response.data.data;
          this.totalUsers = response.data.total;
          this.numberOfPages = response.data.last_page;
        });
    },

    clearSearch() {
      this.search = "";
      this.profile_search = "";
      this.status_user = "";
      this.indoDataTable();
    },

    resetForm() {
      this.$refs.form.reset();
      this.$refs.form.resetValidation();
    },

    loadSelects() {
      axios.get(`/county`).then((response) => {
        this.county_list = response.data;
      });
      axios.get(`/profiles`).then((response) => {
        if (this.$store.state.profile_id == 1) {
          this.profile_list = response.data.data.filter(
            (x) => x.id != 7 && x.id != 5
          );
        }
        if (this.$store.state.profile_id == 2) {
          this.profile_list = response.data.data.filter(
            (x) => x.id != 7 && x.id != 5 && x.id != 1
          );
        }
      });

      axios.get(`/agency`).then((response) => {
        this.agency_list = response.data;
      });

      axios.get(`/institute`).then((response) => {
        this.institute_list = response.data;
      });

      axios.get(`/regional-nucleus-health`).then((response) => {
        this.regional_nucleus_health_list = response.data;
      });

      axios.get(`/state`).then((response) => {
        this.state_list = response.data;
      });
    },

    indoDataTable() {
      this.loading = true;
      const { page } = this.options;
      let pageNumber = page;
      let data = new Object();
      data.per_page = this.options.itemsPerPage;
      axios
        .get(`/users`, {
          params: {
            page: pageNumber,
            per_page: data.per_page,
            name: this.search,
            profile_id: this.profile_search,
            status_user: this.status_user,
          },
        })
        .then((response) => {
          this.loading = false;
          this.usuarios = response.data.data;
          this.totalUsers = response.data.total;
          this.numberOfPages = response.data.last_page;
        });
    },

    create() {
      if (this.$refs.form.validate() == true) {
        const newCPF = this.cpf.replace(/[^a-zA-Z0-9]/g, "");
        const dados = new Object();
        dados.name = this.name;
        dados.cpf = newCPF;
        dados.email = this.email;
        dados.ibge = this.ibge;
        dados.profile_id = this.profile;
        dados.county_id = this.county;
        dados.regional_nucleus_health_id = this.regional_nucleus_health_id;
        dados.state_id = this.state_id;
        dados.agency_id = this.agency_id;
        dados.institute_id = this.institute_id;

        axios
          .post(`/users`, dados)
          .then((response) => {
            this.indoDataTable();
            this.resetForm();
            this.message = response.data.message;
            this.snackbar = true;
            this.dialog = false;
          })
          .catch((err) => {
            err;
            var error = "";
            Object.values(this.$store.state.errorMessage).map((value) => {
              error = value;
            });

            this.displayError.message = error.toString();
            this.displayError.display = true;
            setTimeout(() => {
              this.displayError.display = false;
            }, 3000);
          });
      }
    },

    crudUser(resource) {
      if (resource == "") {
        this.indoDataTable();
      } else {
        axios.delete(`/users/${resource}`).then((response) => {
          this.indoDataTable();
          this.message = response.data.message;
          this.snackbar = true;
        });
      }
    },
  },

  mounted() {
    this.indoDataTable();
    this.loadSelects();
  },
};
</script>

<style scoped>
.header-table {
  display: flex;
  margin-bottom: 20px;
}

.space {
  width: 15px;
}

.v-btn:not(.v-btn--round).v-size--default {
  height: 40px !important;
}

.textBtn {
  font-family: "Poppins";
  color: #ffffff;
}

.create-user-dialog {
  padding: 20px;
}

.card {
  padding: 35px;
}

.banner {
  background: #b3ffab; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #27ae60,
    #1abc9c
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #27ae60,
    #1abc9c
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  border-radius: 8px;

  margin-bottom: 20px;

  display: flex;
  align-items: center;
}

.banner > h4 {
  color: #ffffff;
  padding: 10px;
}

.cardFooter {
  padding: 0 !important;
}
</style>
