<template>
  <div style="display: flex; justify-content: start; align-items: center">
    <v-tooltip bottom color="primary">
      <template v-slot:activator="{ on, attrs }">
        <span v-bind="attrs" v-on="on">
          <v-icon small class="mr-2 iconeEditar" @click="openDialogEdit">
            mdi-pencil
          </v-icon>
        </span>
      </template>
      <span>Editar</span>
    </v-tooltip>

    <v-tooltip bottom :color="switchColor">
      <template v-slot:activator="{ on, attrs }">
        <span v-bind="attrs" v-on="on">
          <v-switch
            v-model="switchRegister"
            color="success"
            value
            @click="swithRegister()"
          ></v-switch>
        </span>
      </template>
      <span>{{ switchStatus }}</span>
    </v-tooltip>

    <v-dialog
      v-model="dialogEditar"
      width="600px"
      class="modelDialog"
      persistent
    >
      <v-card class="card rounded-lg">
        <div class="banner-edit">
          <h4>Editar Informações</h4>
        </div>

        <AlertError v-model="displayError" :resource="displayError" />

        <div class="space"></div>

        <v-form
          @submit.prevent="edit"
          v-model="valid"
          lazy-validation
          ref="form"
        >
          <v-text-field
            label="Nome"
            placeholder="Nome"
            outlined
            v-model="resource.name"
            :rules="nameRules"
            dense
          ></v-text-field>
          <v-text-field
            label="E-mail"
            placeholder="Email"
            outlined
            v-model="resource.email"
            :rules="emailRules"
            dense
          ></v-text-field>
          <v-text-field
            label="CPF"
            placeholder="CPF"
            outlined
            v-mask="'###.###.###-##'"
            v-model="resource.cpf"
            dense
          ></v-text-field>

          <v-select
            dense
            outlined
            v-model="profile_id"
            :items="profile_list"
            :rules="nameRules"
            item-text="name"
            item-value="id"
            label="Perfis"
            return-object
            persistent-hint
          ></v-select>

          <v-select
            style="max-width: 200px"
            dense
            outlined
            :items="agency_list"
            v-model="agency_id"
            item-text="name"
            item-value="id"
            label="Orgão Entidade"
            return-object
            persistent-hint
            clearable
          ></v-select>

          <v-select
            clearable
            v-if="showCounty"
            dense
            outlined
            :items="county_list"
            v-model="county_id"
            :rules="nameRules"
            item-text="name"
            item-value="id"
            label="Municipio"
            return-object
            persistent-hint
          ></v-select>

          <v-select
            clearable
            v-if="showInstitute"
            dense
            outlined
            :items="institute_list"
            v-model="institute_id"
            item-text="name"
            item-value="id"
            label="Instituição"
            return-object
            persistent-hint
          ></v-select>

          <v-select
            clearable
            v-if="showRegionalHealthNucleus"
            dense
            outlined
            :items="regional_nucleus_health_list"
            v-model="regional_nucleus_health_id"
            item-text="name"
            item-value="id"
            label="Macrorregião"
            return-object
            persistent-hint
          ></v-select>

          <v-select
            clearable
            v-if="showState"
            dense
            outlined
            :items="state_list"
            v-model="state_id"
            item-text="name"
            item-value="id"
            label="Estado"
            return-object
            persistent-hint
          ></v-select>

          <v-card-actions class="cardFooter">
            <v-spacer></v-spacer>

            <v-btn
              class="rounded-lg btn"
              color="red"
              text
              elevation="0"
              @click="cancelEdit"
            >
              <span>Cancelar</span>
            </v-btn>

            <v-btn
              class="rounded-lg"
              color="success"
              elevation="0"
              type="submit"
            >
              <span class="textBtn">Enviar dados</span>
              <v-icon small class="ml-2">mdi-arrow-right</v-icon>
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialogAPagar"
      width="400px"
      class="modelDialog"
      persistent
    >
      <v-card class="card rounded-lg">
        <div class="banner-delete">
          <h4>Deletar Usuário</h4>
        </div>

        <div class="textInfo">
          <h6 class="textDelete">
            Deseja deletar <span class="userName">{{ resource.name }}</span> dos
            usuários cadastrados?
          </h6>
          <h6 class="alertText">(Essa ação não pode ser revertida)</h6>
        </div>

        <v-card-actions class="cardFooter">
          <v-btn
            class="rounded-lg btn"
            color="red"
            text
            elevation="0"
            @click="dialogAPagar = false"
          >
            <span>Cancelar</span>
          </v-btn>

          <v-btn
            class="rounded-lg"
            color="#27ae60"
            elevation="0"
            type="submit"
            @click="deletar"
          >
            <span class="textBtn">Confirmar</span>
            <v-icon color="#FFFFFF" small class="ml-2">mdi-arrow-right</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <AlertSuccess v-model="displaySuccess" :resource="displaySuccess" />
  </div>
</template>

<script>
import axios from "@/axios";
import AlertSuccess from "../alerts/Success-component.vue";
import AlertError from "../alerts/Error-component.vue";

export default {
  data() {
    return {
      valid: true,
      nameRules: [(v) => !!v || "O campo é obrigatório"],
      emailRules: [
        (v) => !!v || "O campo é obrigatório",
        (v) => /.+@.+/.test(v) || "O campo e-mail não válido.",
      ],
      cpfRules: [(v) => !!v || "O campo é obrigatório"],
      dialogEditar: false,
      dialogAPagar: false,
      profile_list: [],
      county_list: [],
      profile_id: { id: Number(this.resource.profile_id) },
      county_id: { id: Number(this.resource.county_id) },
      agency_list: [],
      agency_id: { id: Number(this.resource.agency_id) },
      institute_list: [],
      institute_id: { id: Number(this.resource.institute_id) },
      showInstitute: false,
      showState: false,
      showCounty: false,
      showRegionalHealthNucleus: false,
      showAgency: false,
      regional_nucleus_health_list: [],
      regional_nucleus_health_id: {
        id: Number(this.resource.regional_nucleus_health_id),
      },
      switchStatus: "",
      switchColor: "",
      switchRegister: "",
      disabledItensByProfile: "",
      state_list: [],
      state_id: { id: Number(this.resource.state_id) },
      displayError: {
        display: false,
        message: [],
      },
      displaySuccess: {
        display: false,
        message: [],
      },
    };
  },

  props: {
    resource: {
      type: Object,
    },
  },

  components: {
    AlertSuccess,
    AlertError,
  },

  watch: {
    profile_id(val) {
      this.switchProfileControl(val.id);
    },
  },

  created() {
    this.verifyStatusRegister();
  },

  methods: {
    verifyStatusRegister() {
      if (this.resource.deleted_at == null) {
        this.switchRegister = true;
        this.switchStatus = "Desativar";
        this.switchColor = "red";
        return;
      }
      this.switchRegister = false;
      this.switchStatus = "Ativar";
      this.switchColor = "success";
    },
    swithRegister() {
      if (!this.switchRegister) {
        this.disableRegister();
        this.$emit("eventname", "");
        return;
      }

      this.enableRegister();
      this.$emit("eventname", "");
    },
    enableRegister() {
      axios.put(`/users/restore/${this.resource.id}`).then((response) => {
        this.displaySuccess.message = response.data.message;
        this.displaySuccess.display = true;
      });
    },

    disableRegister() {
      axios.delete(`/users/${this.resource.id}`).then((response) => {
        this.displaySuccess.message = response.data.message;
        this.displaySuccess.display = true;
      });
    },

    switchProfileControl(profile_id) {
      switch (Number(profile_id)) {
        case 3:
          this.showCounty = true;
          this.showAgency = true;
          this.showInstitute = false;
          this.showRegionalHealthNucleus = false;
          this.showState = false;
          this.institute_id = 0;
          this.regional_nucleus_health_id = 0;
          this.state_id = 0;
          break;

        case 4:
          this.showInstitute = true;
          this.showAgency = false;
          this.showCounty = false;
          this.showRegionalHealthNucleus = false;
          this.showState = false;
          this.county_id.id = 0;
          this.regional_nucleus_health_id = 0;
          this.state_id = 0;
          break;
        case 6:
          this.showRegionalHealthNucleus = true;
          this.showAgency = true;
          this.showCounty = false;
          this.showInstitute = false;
          this.showState = false;
          this.county_id.id = 0;
          this.institute_id.id = 0;
          this.state_id.id = 0;
          break;

        case 8:
          this.showState = true;
          this.showAgency = true;
          this.showCounty = false;
          this.showInstitute = false;
          this.showRegionalHealthNucleus = false;
          this.county_id.id = 0;
          this.institute_id.id = 0;
          this.regional_nucleus_health_id.id = 0;
          break;

        default:
          this.showAgency = true;
          this.showCounty = false;
          this.showInstitute = false;
          this.showRegionalHealthNucleus = false;
          this.showState = false;
          this.county_id.id = 0;
          this.institute_id = 0;
          this.regional_nucleus_health_id = 0;
          this.state_id = 0;
          break;
      }
    },
    openDialogEdit() {
      axios.get(`/county`).then((response) => {
        this.county_list = response.data;
      });
      axios.get(`/profiles`).then((response) => {
        if (this.$store.state.profile_id == 1) {
          this.profile_list = response.data.data.filter(
            (x) => x.id != 7 && x.id != 5
          );
        }
        if (this.$store.state.profile_id == 2) {
          this.profile_list = response.data.data.filter(
            (x) => x.id != 7 && x.id != 5 && x.id != 1
          );
        }
      });
      axios.get(`/agency`).then((response) => {
        this.agency_list = response.data;
      });
      axios.get(`/institute`).then((response) => {
        this.institute_list = response.data;
      });

      axios.get(`/regional-nucleus-health`).then((response) => {
        this.regional_nucleus_health_list = response.data;
      });

      axios.get(`/state`).then((response) => {
        this.state_list = response.data;
      });

      this.switchProfileControl(this.resource.profile_id);
      this.dialogEditar = true;
    },
    edit() {
      if (this.$refs.form.validate() == true) {
        const newCPF = this.resource.cpf.replace(/[^a-zA-Z0-9]/g, "");
        const dados = new Object();
        dados.id = this.resource.id;
        dados.name = this.resource.name;
        dados.cpf = newCPF;
        dados.email = this.resource.email;
        dados.profile_id = this.profile_id.id == 0 ? null : this.profile_id.id;
        dados.county_id = this.county_id.id == 0 ? null : this.county_id.id;
        dados.institute_id =
          this.institute_id.id == 0 ? null : this.institute_id.id;
        if (this.agency_id) {
          dados.agency_id = this.agency_id.id == 0 ? null : this.agency_id.id;
        }

        axios
          .put(`/users/${dados.id}`, dados)
          .then((response) => {
            this.displaySuccess.message = response.data.message;
            this.displaySuccess.display = true;
            this.dialogEditar = false;
          })
          .catch((err) => {
            err;
            var error = "";
            Object.values(this.$store.state.errorMessage).map((value) => {
              error = value;
            });

            this.displayError.message = error.toString();
            this.displayError.display = true;
            setTimeout(() => {
              this.displayError.display = false;
            }, 3000);
          });
      }
    },

    cancelEdit() {
      this.dialogEditar = false;
      this.$emit("eventname", "");
    },

    deletar() {
      this.dialogAPagar = false;
      this.$emit("eventname", this.resource.id);
    },
  },
};
</script>

<style scoped>
.space {
  height: 10px;
}

.iconeEditar:hover {
  color: #54c6eb;
}

.iconeDeletar:hover {
  color: #ad2831;
}

.card {
  padding: 20px;
}

.banner-edit {
  background: #e55d87;
  background: linear-gradient(
    160deg,
    rgba(95, 195, 228, 1) 0%,
    rgba(93, 148, 229, 1) 100%
  );
  border-radius: 5px;

  margin-bottom: 20px;

  display: flex;
  align-items: center;
}

.banner-delete {
  background: #e55d87;
  background: linear-gradient(
    160deg,
    rgb(235, 30, 57) 0%,
    rgb(248, 96, 36) 100%
  );
  border-radius: 5px;

  margin-bottom: 20px;

  display: flex;
  align-items: center;
}

.banner-edit > h4,
.banner-delete > h4 {
  color: #ffffff;
  padding: 10px;
}

.textInfo {
  padding: 15px;
  border: 1px solid rgb(207, 207, 207);
  border-radius: 8px;
  text-align: center;
}

.textDelete {
  font-family: "Poppins";
  font-weight: 400;

  font-size: 14px;
}

.userName {
  font-family: "Poppins";
  font-weight: 700;
  font-size: 14px;
  color: #000000;
}

.alertText {
  font-family: "Poppins";
  font-weight: 500;
  font-size: 12px;

  margin-top: 15px;

  color: #f44336;
}

.cardFooter {
  display: flex;
  justify-content: center;

  margin-top: 20px;
}

.cardFooter {
  padding: 0 !important;
}

.textBtn {
  font-family: "Poppins";
  color: #ffffff;
}
</style>
